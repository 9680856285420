import React from 'react'

const CurrentWeek = () => {
    const startDate = new Date('2024-02-03T00:00:00+02:00'); // Starting date of odd week in Chisinau timezone (UTC+3)
      
    // Get the current date in the Europe/Chisinau timezone
    const currentDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Chisinau' }));
  
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const diffWeeks = Math.floor((currentDate - startDate) / oneWeek);
    return diffWeeks % 2 === 0 ? 'odd' : 'even';
  };

export default CurrentWeek;
